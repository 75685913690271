import React, { Component } from 'react';
import classes from './ResetPassword.module.css';
import Axios from '../../apis/config';
import Logo from '../../assets/logo.png';


class ResetPassword extends Component {


    state = {
        newPassword: '',
        confirmPassword: '',
        success: false,
        userId: null,
        resetToken: null
    };

    onResetPassword = (url) => {

        Axios.get(url)
            .then(response => {
                if (response.status === 200) {
                    this.setState({ userId: response.data.data._id, resetToken: response.data.data.resetToken })
                } else {
                    console.log(response.data.err);

                }
            })
            .catch(err => {
                console.log(err);

            });
    }

    onChangePassword = (id, resetToken, password) => {
        Axios.post('/new-password',
            {
                userId: id,
                resetToken: resetToken,
                password: password
            })
            .then(response => {
                if (response.status === 200) {
                    this.props.history.push('/');
                } else {
                    console.log(response.data.err);

                }
            })
            .catch(err => {
                console.log(err);

            });
    }


    componentDidMount() {
        this.onResetPassword(window.location.pathname);
    }

    handleInputChange = (event) => {
        const { value, name } = event.target;
        this.setState({
            [name]: value
        });
    }

    submitHandler = (event) => {
        event.preventDefault();
        if (this.state.newPassword !== this.state.confirmPassword) {
            alert('Password do not match.');
        } else {
            this.onChangePassword(this.state.userId, this.state.resetToken, this.state.newPassword);
            this.setState({ success: true });
        }
    }

    render() {
        let form = <div className={classes.loginBox}>
            <div className={classes.hello}>
                <h4 className={classes.headingText}>CHANGE PASSWORD{this.props.loading}</h4>
                <form onSubmit={this.submitHandler}>

                    <div className={classes.inputDiv}>
                        <p className={[classes.spanText, classes.spanSize].join(' ')}>NEW PASSWORD : </p>
                        <input className={classes.input} name="newPassword"
                            value={this.state.email}
                            onChange={this.handleInputChange}
                            required />
                    </div>

                    <div className={classes.inputDiv}>
                        <p className={[classes.spanText, classes.spanSize].join(' ')}>CONFIRM PASSWORD : </p>
                        <input className={classes.input} name="confirmPassword"
                            value={this.state.email}
                            onChange={this.handleInputChange}
                            required />
                    </div>

                    <div className={classes.buttonDiv}>
                        <button className={classes.button} >CHANGE PASSWORD</button>
                    </div>
                </form>
            </div>
        </div>
        if (this.state.success) {
            this.props.history.push('/');
        }
        return (
            < div className={classes.container} >
                <header className={classes.header}>
                    <img src={Logo} alt="ITK Logo" />
                </header>
                {form}
            </div >
        )
    }
}


export default ResetPassword;