import React, { Component } from 'react';
import classes from './ForgotPassword.module.css';
import axios from '../../apis/config';
import Logo from '../../assets/logo.png';



class ForgotPassword extends Component {


    state = {
        email: '',
        visible: false,
        message: ""
    };


    handleInputChange = (event) => {
        const { value, name } = event.target;
        this.setState({
            [name]: value
        });
    }


    onChangePassword = (email) => {
        axios.post('/reset', { email: email })
            .then(result => {
                if (result.status === 200) {
                    this.setState({ message: result.data.data })
                    return;
                } else {
                    this.setState({ message: result.data.data })
                    console.log(result.data);
                }
            })
            .catch(err => {
                this.setState({ message: "This email id not exist in out database." })
                console.log(err);
            })

    }

    submitHandler = (event) => {
        event.preventDefault();
        this.onChangePassword(this.state.email);
        this.setState({ visible: true });
    }

    render() {
        var render;
        render = <div className={classes.loginBox}>
            <div className={classes.hello}>
                <h4 className={classes.headingText}>RESET PASSWORD{this.props.loading}</h4>
                <form onSubmit={this.submitHandler}>
                    <div className={classes.inputDiv}>
                        <p style={{ marginLeft: '50px', marginRight: '50px', fontSize: '11px', fontWeight: 'bold' }}>Enter your email id in the box below
                and access your mail to reset your password.</p>
                        <p className={[classes.spanText, classes.spanSize].join(' ')}>EMAIL : </p>
                        <input className={classes.input} name="email"
                            value={this.state.email}
                            onChange={this.handleInputChange}
                            required />
                    </div>

                    <div className={classes.buttonDiv}>
                        <button className={classes.button} >RESET</button><br />
                        {this.state.visible ? <span style={{ marginBottom: '-15px', fontSize: '12px', color: "#219A99" }}>{this.state.message}</span> : null}
                    </div>

                </form>
            </div>
        </div>

        return (
            < div className={classes.container} >
                <header className={classes.header}>
                    <img src={Logo} alt="ITK Logo" />
                </header>
                {render}
            </div >
        )
    }
}


export default ForgotPassword;