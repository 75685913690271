import React from "react";
import classes from "./Register.module.css";
import Input from "../../components/Input/Input";
import Button from '../../components/Button/Button';
import registerForm from './registerData';
import AuthLayout from '../../components/AuthLayout/AuthLayout';
import { Link } from 'react-router-dom';
import axios from '../../apis/config';
import checkValidity from '../../utilities/Validation';

class Register extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            registerForm: registerForm,
            error_message: ""
        };
    }


    onRegister = (firstname, lastname, email, phone, username, password) => {
        const authData = {
            firstname: firstname,
            lastname: lastname,
            phone: phone,
            email: email,
            password: password,
            username: username
        }
        axios.post('/register', authData)
            .then(response => {
                if (response.status === 200) {
                    this.props.history.push('/');
                } else {
                    console.log(response.data.error);
                }

            })
            .catch(err => {
                console.log(err);

            });
    }


    inputChangedHandler = (event, inputIdentifier) => {
        const updatedFormData = {
            ...this.state.registerForm,
            [inputIdentifier]: {
                ...this.state.registerForm[inputIdentifier],
                value: event.target.value,
                valid: checkValidity(event.target.value, this.state.registerForm[inputIdentifier].validation),
            }
        };

        this.setState({
            registerForm: updatedFormData
        });
    };

    formSubmitHandler = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (!this.state.registerForm.firstname.valid) {
            this.setState({ error_message: 'Please enter firstname' });
        }
        else if (!this.state.registerForm.lastname.valid) {
            this.setState({ error_message: 'Please enter lastname' });
        }
        else if (!this.state.registerForm.email.valid) {
            this.setState({ error_message: 'Please enter valid email' });
        }
        else if (!this.state.registerForm.phone.valid) {
            this.setState({ error_message: 'Please enter valid contact of 10 digit' });
        }
        else if (!this.state.registerForm.username.valid) {
            this.setState({ error_message: 'Please enter Username' });
        }
        else if (!this.state.registerForm.password.valid) {
            this.setState({ error_message: 'Password minimum 5 character' });
        } else {
            this.setState({ loading: true, error_message: "" });
            const formData = {};
            for (let formElementIdentifier in this.state.registerForm) {
                formData[formElementIdentifier] = this.state.registerForm[formElementIdentifier].value;
            }

            //send this formdata variable to axios API 
            this.onRegister(formData.firstname, formData.lastname, formData.email, formData.phone, formData.username, formData.password);
        }
    }

    render() {
        // if (this.props.success) {
        //     this.props.history.push('/');
        // }

        const formElementsArray = [];
        for (let key in this.state.registerForm) {
            formElementsArray.push({
                id: key,
                config: this.state.registerForm[key],
            });
        }

        return (
            <AuthLayout>
                <p style={{ fontSize: '10px', color: 'red', textTransform: 'uppercase' }}>{this.state.error_message}</p>
                <h3 className={classes.title}>Sign Up</h3>
                <form onSubmit={this.formSubmitHandler}>
                    {formElementsArray.map((formElement) => {
                        return (
                            <Input
                                className={classes.input}
                                key={formElement.id}
                                text={formElement.config.text}
                                elementType={formElement.config.elementType}
                                elementConfig={formElement.config.elementConfig}
                                value={formElement.config.value}
                                changed={(event) => this.inputChangedHandler(event, formElement.id)}
                            />
                        );
                    })}
                    <div className={classes.buttonDiv}>
                        <Button>SIGN UP</Button>
                        <Link to="/" style={{ textDecoration: 'none', color: 'black' }}>
                            <p className={classes.spanSize}>Already member ? Login here.</p></Link>
                    </div>
                </form>
            </AuthLayout>
        );
    }
}



export default Register;
