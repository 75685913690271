import React from 'react';
import { HashRouter, Switch, Route } from 'react-router-dom';

import Login from './containers/Login/Login';
import Register from './containers/Register/Register';
import ForgotPassword from './containers/ForgotPassword/ForgotPassword';
import ResetPassword from './containers/ResetPassword/ResetPassword';

import './App.css';
import Logout from './containers/Logout/Logout';

function App() {
  return (
    <React.Fragment>
      <HashRouter>
        <Switch>
          <Route path="/" exact component={Login} />
          <Route path="/forget-password" exact component={ForgotPassword} />
          <Route path="/register" exact component={Register} />
          <Route path="/auth/reset/:resetId" exact component={ResetPassword} />
          <Route path="/logout" exact component={Logout} />
        </Switch>
      </HashRouter>
    </React.Fragment>
  );
}

export default App;
