import React from "react";
import axios from '../../apis/config';
import cookie from 'react-cookies'

import AuthLayout from '../../components/AuthLayout/AuthLayout';
import Button from '../../components/Button/Button';

import classes from "./Login.module.css";
import { Link } from "react-router-dom";

class Login extends React.Component {

    constructor(props){
        super(props);
        if(cookie.load('userId')){
            window.location = process.env.REACT_APP_AFTERSCHOOL_URL;
        }
    }
    state = {
        username: '',
        password: '',
        message: '',
        visible: false
    }

    onInputChangeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onFormSubmit = (e) => {
        e.preventDefault();

        axios.post('/login', { username: this.state.username, password: this.state.password })
            .then(result => {

                if (result.status === 200) {
                    this.setState({ message: "", visible: false });
                    var expiresIn = JSON.parse(result.data.expiresIn);
                    var dateExpiresIn = new Date(parseInt(expiresIn));
                    cookie.save('userId', result.data.userId, { path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN, sameSite: "lax", expires: dateExpiresIn });
                    cookie.save('token', result.data.token, { path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN, sameSite: "lax", expires: dateExpiresIn });
                    cookie.save('expiresIn', JSON.parse(result.data.expiresIn), {path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN, sameSite: "lax", expires: dateExpiresIn});
                    const query = new URLSearchParams(this.props.location.search);
                    if (query.get('redirectURL')) {
                        window.location = query.get('redirectURL');
                        return null;
                    }else{
                        window.location = process.env.REACT_APP_AFTERSCHOOL_URL
                    }
                } else {
                    this.setState({ message: result.data, visible: true });
                }
            })
            .catch(err => {
                this.setState({ message: 'Invalid email or password', visible: true })
                console.log(err);
            })
    }

    render() {
        
        return (
            <AuthLayout>
                <div>
                    {this.state.visible ? <p className={classes.spanSize} style={{ color: 'red' }}>{this.state.message}</p> : null}
                    <h3 className={classes.title}>Sign In</h3>
                    <form onSubmit={(e) => this.onFormSubmit(e)}>
                        <div className={classes.fieldContainer}>
                            <span className={classes.label}>Username</span>
                            <input className={classes.input} type="text" name="username" placeholder="Your Username" value={this.state.username} onChange={this.onInputChangeHandler} />
                        </div>
                        <div className={classes.fieldContainer}>
                            <span className={classes.label}>Password</span>
                            <input className={classes.input} type="password" name="password" placeholder="Your Password" value={this.state.password} onChange={this.onInputChangeHandler} />
                        </div>
                        <div className={classes.rememberDiv}>
                            <div className={classes.rememberMeDiv}>
                                <p className={classes.spanSize} style={{ marginRight: '5px' }}>Remember me :</p>
                                <input type="checkbox" checked={this.state.checked} onChange={this.handleCheckClick} />
                            </div>
                            <div>
                                <Link to="/forget-password" style={{ textDecoration: 'none', color: 'black' }}>
                                    <p className={classes.spanSize}>Forgot Password ?</p></Link></div>
                        </div>
                        <div className={classes.buttonDiv}>
                            <Button>SIGN IN</Button>
                            <Link to="/register" style={{ textDecoration: 'none', color: 'black' }}>
                                <p className={classes.spanSize} style={{ marginTop: '10px' }}>New user ? Register here.</p></Link>
                        </div>
                    </form>
                </div>
            </AuthLayout>
        )
    }
}

export default Login;