import React from 'react';
import cookie from 'react-cookies'

class Logout extends React.Component{
    render(){
        cookie.remove('userId', { path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN});
        cookie.remove('token',  { path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN});
        cookie.remove('expiresIn',  { path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN});
        window.location = process.env.REACT_APP_URL;
        return(
            <h1>Loading</h1>
        )
    }
}

export default Logout;